
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































































































.process {
  @include liquid(padding-block, 40px, 80px);

  background-color: $c-pampas;
}

.process-inner {
  text-align: center;
}

.process__items {
  display: flex;
  align-items: center;
  overflow: auto;
}

.process__item {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  width: 0 0 25%;
  min-width: 30rem;
  height: 14rem;
  padding-left: 3rem;
  background-color: $c-white;

  span {
    @include liquid(font-size, 15px, 2px);

    font-weight: 600;
    line-height: 120%;
    text-align: left;
  }
}

.process__item__icon {
  width: 4rem;
  height: 4rem;
  margin-right: 0.8rem;
}

.separator {
  position: relative;
  width: 6rem;
  height: 100%;
  margin-left: auto;
  background-color: $c-white;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 2rem;
    height: 100%;
    background-color: $c-pampas;
  }
}

.separator__back {
  position: absolute;
  top: 0;
  right: 0;
}

.separator__front {
  position: absolute;
  top: 0;
  left: 0;
}
