
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































.job-details-inner {
  @include mq(l) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.job-details__nav {
  flex: 0 0 auto;

  @include mq(l) {
    width: col(3, 22);
  }
}

.job-details__nav-inner {
  position: sticky;
  top: 10rem;
}

.job-details__nav__share {
  @include liquid(font-size, 13px, 15px);

  margin-top: 1rem;
  line-height: 24px;
}

.job-details__content {
  flex: 1;

  @include mq(l) {
    width: col(12, 22);
    padding-inline: col(3, 22);
  }
}

.button--has-curtain--light {
  border: 0;

  ::v-deep {
    .curtain__inner {
      background-color: $c-pampas;
    }
  }
}
