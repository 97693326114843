
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































































































.job-cta {
  @include liquid(padding-block, 30px, 60px);

  position: relative;
  background-color: $c-main;
}

.job-cta-inner {
  @include mq(l) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}

.job-cta__content {
  color: $c-white;

  @include mq(l) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: col(11, 20);
    margin-left: col(1, 20);
  }
}

.job-cta__content__title {
  margin-bottom: 0;
}

.job-cta__content__htmltext {
  @include liquid(margin-bottom, 0, 40px);

  margin-top: 1rem;
}

.job-cta__content__icon {
  fill: $c-white;
  transition: all $easing $transition-duration;

  .job-cta:hover & {
    transform: translateX(1rem);
  }
}

.job-cta__picture-container {
  position: relative;
  overflow: hidden;
  aspect-ratio: 800/515;

  ::v-deep {
    .clip {
      aspect-ratio: 800/515;
    }

    .job-cta__picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include mq(l) {
    width: col(9, 20);
  }
}

.job-cta__content__link {
  display: inline-flex;

  &::before {
    @include get-all-space;

    content: '';
  }
}
