
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































































.hero-job {
  color: $c-white;
  background-color: $c-black;
}

.hero-job-inner {
  @include mq(l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.hero-job__content {
  padding-block: 3rem;

  @include mq(l) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.hero-job__headline {
  margin-bottom: 2rem;
}

.hero-job__title {
  margin-bottom: 2rem;
}

.hero-job__visual {
  background-color: $c-main;
}

.hero-job__details {
  opacity: 0.4;
}
